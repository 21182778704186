<template>
  <main>
    <v-card v-for="offer of offers.list" :key="offer._id">
      <v-card-text class="offer">
        <div class="body mr-4">
          <div class="offer-title">
            <div class="d-flex">
              <v-avatar color="#eee" rounded class="mr-3">
                <img :src="offer.image || offer.project.logo || require('@/assets/images/content/marketplace_item.png')" class="w-100" alt="Avatar">
              </v-avatar>
              <div class="offer-title__info pt-1 mr-4">
                <h3 class="offer-name">{{offer.name.toUpperCase()}}</h3>
                <span class="project-name">{{offer.project.name}}</span>
              </div>
            </div>
            <div class="offer-title__tags">
              <v-chip small rounded color="primary" class="mr-2" v-if="showNewChip(offer)">New</v-chip>
              <!-- <v-chip small rounded color="error">Manual payout</v-chip> -->
            </div>
          </div>
          <div class="offer-desc">{{offer.description}}</div>

          <div class="d-flex">
            <div class="property">
              <span class="property-title">{{getOfferPayout(offer)}} {{offer.token}}</span>
              <span class="property-name">Payout</span>
            </div>

            <!-- <div class="property">
              <span class="property-title">{{offer.blockchain}}</span>
              <span class="property-name">Blockchain</span>
            </div> -->
          </div>
        </div>


        <div class="promote" @click="promote(offer)" v-if="canPromote(offer)">
          Promote
        </div>

        <div class="comming-soon" v-else>
          Coming <br> Soon
        </div>
      </v-card-text>
    </v-card>

    <!-- <div class="text-center">
      <v-pagination
        color="primary"
        v-model="page"
        :length="offers.meta.totalPages"
        @input="getOffers"
        @next="getOffers"
        @previous="getOffers"
      ></v-pagination>
    </div> -->
  </main>
</template>

<script>
import { mdiInformation, mdiReload, mdiFileEdit, mdiShieldLock } from '@mdi/js'
import { mapGetters } from 'vuex'

import moment from 'moment'

export default {
  data: () => ({
    expanded: [],
    icons: { mdiInformation, mdiReload, mdiFileEdit, mdiShieldLock },
    page: 1,
    offers: {
      loading: false,
      list: [],
      events: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 5,
      },
    },
  }),
  computed: {
    ...mapGetters('web3auth', ['userdata']),
  },
  created() {
    this.getOffers({ page: 1 })
  },
  methods: {
    showNewChip(item) {
      return moment().diff(moment(item.createdAt), 'days') < 7
    },

    getOffers(query) {
      this.offers.loading = true

      const params = {
        // limit: this.offers.options.itemsPerPage,
        // page: this.page,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('/publisher/offers', { params })
        .then(({ data }) => {
          const { meta, docs, events } = data

          this.offers.meta = meta
          this.offers.list = docs
          this.offers.events = events
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.offers.loading = false
          })
        })
    },

    promote(item) {
      navigator.clipboard.writeText(`${item.link}?aff=${this.userdata.wallet}`)

      this.$store.dispatch(
        'notification/GENERATE_NOTIFICATION',
        {
          type: 'success',
          message: 'Successfully copied to clipboard!',
        },
        { root: true },
      )
    },

    getOfferPayout(offer) {
      // NOTE: 10% NFTIZ commission
      return (offer.payout - (offer.payout / 100) * 10).toFixed(2)
    },

    canPromote(offer) {
      return this.offers.events.findIndex(it => it.offer === offer._id) !== -1
    },
  },
}
</script>

<style lang="scss" scoped>
.offer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px 20px;
}

.body {
  display: flex;
  flex-direction: column;

  .offer-title {
    display: flex;
    margin-bottom: 15px;

    .v-avatar {
      border: 1px solid #eee;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .project-name {
      color: #b5b5c3;
      line-height: 1;
    }

    .offer-name {
      color: #000;
      line-height: 1;
      text-transform: none;
    }
  }

  .offer-desc {
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
  }
}

.property {
  display: flex;
  flex-direction: column;
  padding: 8px 15px;
  border: 1px dashed #7e7e7e;
  border-radius: 5px;
  margin-right: 10px;

  &-title {
    color: #000;
    font-size: 13px;
    font-weight: 600;
  }

  &-name {
    font-size: 12px;
  }
}

.promote {
  text-align: center;
  display: flex;
  align-items: center;
  background: linear-gradient(var(--v-primary-base), #88ccd9);
  color: #fff;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s;

  &:hover {
    transform: scale(1.03);
  }
}

.comming-soon {
  text-align: center;
  display: flex;
  align-items: center;
  background: var(--v-secondary-base);
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  transition: 0.3s;
}

.v-card.theme--dark {
  .offer-desc {
    color: #fff !important;
  }

  .property-title {
    color: #fff !important;
  }
}
</style>
